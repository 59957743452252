@import "fonts";
@import "websites/variables";
@import "node_modules/bootstrap/scss/bootstrap.scss";
html {
    scroll-behavior: smooth;
}

#main {
    transition: transform .5s ease;
}

@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }
    .sidenav a {
        font-size: 18px;
    }
}

body {
    overflow-x: inherit;
    overflow-y: inherit;
    position: relative;

}

body {
    font-family: $font-th;
    background-image: url('/images/bg.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
}

.navbar {
  input, button {
    font-size: 12px
  }
}

.nav-link {
  color: $secondary-color;
  font-size: 12px;
  @include hover-focus() {
    color: $white;
  }
}

.text-header {
  font-size: clamp(15px,2vw,30px);
  color: #22226f;
}

.btn-login {
  background-color: $secondary-color;
  color: black;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-width: 0px;
  border-radius: 30px;
  &:hover {
    background-color: $secondary-color-hover;
    color: white;
  }
}


@media (max-width: 675px) {
  .input-login{
    width: 90px;
  }
}

@media (max-width: 420px) {
  .form-login {
    width: 100%;
  }
  .input-login {
    min-width: 0;
    flex: 1 1 100%;
  }
  .btn-login {
    flex: 0 0 auto;
  }
}

